import React, { useMemo } from 'react';
import {
  darkenColor,
  lightenColor,
  stringToColour,
} from '../../helpers/functions';
import { useSocket } from '../../hooks/useSocket';

const presenceLookup = {
  online: (
    <div className=" w-min--10 w-max--10 h-min--10 h-max--10 radius--full position--absolute bg--success border-full--white bottom--0 right--0" />
  ),
  offline: (
    <div className=" w-min--10 w-max--10 h-min--10 h-max--10 radius--full position--absolute bg--grey border-full--white bottom--0 right--0" />
  ),
};

const Presence = ({ userId = null }) => {
  const { sessions = [] } = useSocket();

  const mainSessions = useMemo(
    () =>
      sessions && sessions.length > 0
        ? sessions.map((session) => session?.userID)
        : [],
    [sessions?.length],
  );

  if (!userId) return presenceLookup['offline'];

  if (mainSessions?.includes(userId)) {
    return presenceLookup['online'];
  }

  return presenceLookup['offline'];
};

const Avatar = ({
  src = null,
  height = '40px',
  width = '40px',
  firstname = '',
  lastname = '',
  showPresence = true,
  userId = null,
}) => {
  const name = firstname + ' ' + lastname;
  const nameColour = stringToColour(name);
  const lightColor = lightenColor(nameColour, 90);
  const darkColor = darkenColor(nameColour, 80);

  if (src) {
    return (
      <img
        src={src}
        alt="..."
        className="chtaAvtar radius--full bg--white text--black border-full--black-100 d--flex align-items--center justify-content--center position--relative"
        style={{ height, width, minWidth: width }}
      />
    );
  } else {
    return (
      <div
        className="chtaAvtar radius--full bg--white text--black border-full--black-100   d--flex align-items--center justify-content--center position--relative"
        style={{
          height,
          width,
          minWidth: width,
          // color: lightColor,
          // background: darkColor,
        }}
      >
        <div className=" font--12 font--600">
          {firstname?.at(0)?.toUpperCase() ?? ''}
          {lastname?.at(0)?.toUpperCase() ?? ''}
        </div>
        {showPresence && <Presence userId={userId} />}
      </div>
    );
  }
};

export default Avatar;
